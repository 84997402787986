import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import AuthService from "../../utils/AuthService";

export default function ForgotPassword({ emitFormSubmit }: any) {
  const { t } = useTranslation();

  type ForgottenPsw = {
    emailAddress: string;
  };

  const schema = yup.object().shape({
    emailAddress: yup
      .string()
      .required(t("errorMessages.required"))
      .trim()
      .email(t("errorMessages.invalidEmail")),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgottenPsw>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ForgottenPsw): Promise<void> => {
    await AuthService.forgotPasswordApi(data.emailAddress);
    emitFormSubmit();
  };

  return (
    <div className="flex flex-column flex-wrap align-content-center gap-4">
      <div>
        <h1 className="manuka-header">{t("step2.forgottenPswTitle")}</h1>
        <p className="md:text-lg">{t("step2.forgottenPswDescription")}</p>
      </div>
      <form
        className="gap-4 flex flex-column"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            name="emailAddress"
            control={control}
            render={({ field }) => (
              <div className="flex flex-column gap-2">
                <label htmlFor="emailAddress" className="text-sm">
                  {t("step2.login.passwordInputLabel")}
                </label>
                <InputText
                  {...field}
                  placeholder={t("step2.login.passwordInputPlaceholder")}
                  id="emailAddress"
                  className={errors.emailAddress ? "p-invalid-input" : ""}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </div>
            )}
          />
          {errors.emailAddress && (
            <span className="p-invalid">{errors.emailAddress.message}</span>
          )}
        </div>
        <div className="flex justify-content-center mt-5">
          <Button type="submit">{t("buttons.sendBtn")}</Button>
        </div>
      </form>
    </div>
  );
}
