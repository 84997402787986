import axios from "axios";
import type { AxiosInstance } from "axios";

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    Accept: "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY!,
  },
});

export const authHttpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    Accept: "application/json",
  },
});

export const apiKeyHttpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    Accept: "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY!,
  },
});

export default httpClient;
