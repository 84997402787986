import { authHttpClient } from "../http-common";
import { GetUserProfileByUsedIdResponse } from "../models/ApiResponse";

class UserService {
    async getUserProfileByUserId(query: string, token: string):Promise<GetUserProfileByUsedIdResponse> {
        const response = await authHttpClient.post("", query, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data.data.getUserProfileByUserId;
    }
}


const userService = new UserService();
export default userService;