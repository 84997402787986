import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next"
import AuthService from "../../utils/AuthService";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateMemberShip, updateUserData } from "../../redux/actions/UserdataActions";
import { UserData } from "../../types/UserTypes";
import { LoginFormType } from "../../types/FormTypes";
import userService from "../../services/UserService";
import { getUserProfileByUserId } from "../../utils/GraphqlQueries";

export default function LoginForm({ moveNext, forgotPswClicked }: any) {
    const { t } = useTranslation();
    const [loginError, setLoginError] = useState<string>();
    const [loginBtnLoading, setLoginBtnLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        userName: yup.string().required(t("errorMessages.required")).trim(),
        password: yup.string().required(t("errorMessages.required")).trim(),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<LoginFormType>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: LoginFormType): Promise<void> => {
        try {
            setLoginBtnLoading(true);
            const user = await AuthService.login(data.userName, data.password)
            const userData: UserData = {
                UserId: user.username,
                UserToken: user.signInUserSession.accessToken.jwtToken,
                RefreshToken: user.signInUserSession.refreshToken.token,
                UserName: user.attributes.name + " " + user.attributes.family_name,
                Email:  user.attributes.email,
            }
            dispatch(updateUserData(userData));

            const graphql: string = JSON.stringify({
                query: getUserProfileByUserId(userData.UserId),
                variables: {}
            });

            const response = await userService.getUserProfileByUserId(graphql, userData.UserToken);
            dispatch(updateMemberShip(response.membership));
            
            moveNext();
        } catch (err: any) {
            if (!err.message) {
            } else {
                if (err.code === "UserNotConfirmedException") {
                    setLoginError(t("errorMessages.UserNotConfirmedException"));
                } else if (err.code === "UserNotFoundException") {
                    setLoginError(t("errorMessages.UserNotFoundException"));
                } else if (err.code === "NotAuthorizedException") {
                    setLoginError(t("errorMessages.NotAuthorizedException"));
                } else if (err.message) {
                    setLoginError(err.message)
                } else {
                    setLoginError(t("errorMessages.unkownError"));
                }
            }
        } finally {
            setLoginBtnLoading(false);
        }
    };

    const forgotPassword = (): void => {
        forgotPswClicked();
    }

    return (
        <form className="flex flex-column gap-4 md:w-4 w-10 justify-content-center align-items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column gap-3 justify-content-center flex-wrap w-full md:w-8">
                <div className="flex-1">
                    <Controller
                        name="userName"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="userName" className="text-sm">{t("step2.login.userNameInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} placeholder={t("step2.login.userNameInputPlaceholder")} id="userName" className={errors.userName ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.userName && (
                        <span className="p-invalid">{errors.userName.message}</span>
                    )}
                </div>
                <div className="flex-1">
                    <Controller
                        name="password"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="password" className="text-sm">{t("step2.login.passwordInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} type="password" placeholder={t("step2.login.passwordInputPlaceholder")} id="password" className={errors.password ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.password && (
                        <span className="p-invalid">{errors.password.message}</span>
                    )}
                </div>
            </div>
            {loginError ? (
                <span className="p-invalid">{loginError}</span>
            ) : null}
            <div className="flex justify-content-center mt-5">
                <Button type="submit" loading={loginBtnLoading}>{t("buttons.loginBtn")}</Button>
            </div>
            <div className="flex justify-content-center mb-6">
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                {/* eslint-disable-next-line */}
                <a className="underline cursor-pointer" onClick={forgotPassword}>{t("buttons.forgotPswBtn")}</a>
            </div>
        </form>
    );
};