import { Membership } from "../models/Membership";

export interface UserDataState {
  userData: UserData | null;
  memberShipData: Membership | null;
}

export interface UserData {
  UserId: string;
  UserToken: string;
  RefreshToken: string;
  UserName: string;
  Email: string;
}

export enum UserDataActionTypes {
  UPDATE_USERDATA = "UPDATE_USERDATA",
  UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP",
  RESET_USERDATA = "RESET_USERDATA",
}

export interface UpdateUserDataAction {
  type: UserDataActionTypes.UPDATE_USERDATA;
  payload: UserData;
}

export interface ResetUserDataAction {
  type: UserDataActionTypes.RESET_USERDATA;
  payload: null;
}

export interface UpdateMembershipAction {
  type: UserDataActionTypes.UPDATE_MEMBERSHIP;
  payload: Membership;
}

export type UserDataAction =
  | UpdateUserDataAction
  | ResetUserDataAction
  | UpdateMembershipAction;
