import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./styles/globals.css";
import "./styles/custom.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RedeemForm from "./components/RedeemForm";
import { Amplify } from "aws-amplify";
import { addLocale } from "primereact/api";
import en from "./i18n/primereact/en";
import nl from "./i18n/primereact/nl";
import { AwsSettings } from "./models/AwsSettings";
import 'primeicons/primeicons.css';
import { Route, Routes } from "react-router-dom";
import Terms from "./components/Terms";
import { useEffect } from "react";
import i18n from "./i18n/i18n";
import { useTranslation } from "react-i18next"
import { Helmet, HelmetProvider } from "react-helmet-async";

declare global {
  interface Window {
    _mtm : any;
  }
}

function App() {
  addLocale("nl", nl);
  addLocale("en", en);

  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=`${process.env.REACT_APP_MATOMO_SRC}`; s.parentNode?.insertBefore(g,s);
  }, []);

  const helmetContext = {};
  const { t, i18n } = useTranslation();

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>{t('metadata.title')}</title>
        <meta name='description' content={t('metadata.description')} />
        <meta property="og:url" content={t('metadata.ogUrl')} />
        <meta property="og:title" content={t('metadata.ogTitle')} />
        <meta property="og:site_name" content={t('metadata.ogSiteName')} />
        <meta property="og:description" content={t('metadata.ogDesc')} />
      </Helmet>
      <main>
        <Header />
        <div className="p-4">
          <Routes>
            <Route path="*" element={<RedeemForm/>} />
            <Route path="/terms-conditions" element={<Terms/>} />
          </Routes>
        </div>
        <Footer />
      </main>
    </HelmetProvider>
  );
}

const amplifyConf: AwsSettings = {
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID!,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION!,
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION!,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID!,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID!,
  oauth: {},
};

Amplify.configure(amplifyConf);

export default App;
