import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { supportedLngs } from "../i18n/i18n";

interface LangOptions {
  label: string;
  value: string;
}

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const langOptions: LangOptions[] = supportedLngs.map((lang) => {
    return { label: lang.toUpperCase(), value: lang };
  });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    const url = new URL(window.location.href);
    url.searchParams.set("lng", lang);
    window.history.pushState({}, '', url.toString());
  
  };

  return (
    <div className="flex align-items-center flex-row gap-2">
      <i className="pi pi-globe" style={{ fontSize: '1.25rem' }}></i>
      <Dropdown
        value={i18n.resolvedLanguage}
        onChange={(e) => changeLanguage(e.value)}
        options={langOptions}
        optionLabel="label"
        defaultValue={i18n.resolvedLanguage}
        className="lang-dropdown"
      />
    </div>
  );
}
