import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next"
import { getProductByVoucher } from "../../utils/GraphqlQueries"
import VoucherService from "../../services/VoucherService"
import { useDispatch, useSelector } from "react-redux";
import { updateGiftCard, updateGiftCardCode } from "../../redux/actions/GiftcardActions";
import { RootState } from "../../redux/rootState";
import { ErrorMessage, GetProductByVoucherResponse } from "../../models/ApiResponse";
import { GiftCardFormType } from "../../types/FormTypes";

export default function Step1(props: any) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [redeemSuccesful, switchRedeem] = useState(false);
    const [redeemBtnLoading, setRedeemBtnLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState([] as ErrorMessage[]);
    const giftCard = useSelector((state: RootState) => state.giftCard.giftCard);

    const schema = yup.object().shape({
        giftCardCode: yup.string().trim().required(t("errorMessages.required")).min(12, t("errorMessages.codeMinLength", {len: 12})),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<GiftCardFormType>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: GiftCardFormType): Promise<void> => {
        try {
            setRedeemBtnLoading(true);
            setApiError([]);
            let graphql: string = JSON.stringify({
                query: getProductByVoucher(i18n.language, data.giftCardCode),
                variables: {}
            });

            let giftCardData: GetProductByVoucherResponse = await VoucherService.getVouchers(graphql);
            if (giftCardData.product && giftCardData.isSuccess) {
                dispatch(updateGiftCard(giftCardData.product));
                dispatch(updateGiftCardCode(data.giftCardCode));
                switchRedeem(!redeemSuccesful);
            } else if (giftCardData.errors) {
                setApiError(giftCardData.errors);
            } else {
                setApiError([{code: "0", message: t("errorMessages.invalidCode")}]);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setRedeemBtnLoading(false);
        }
    };

    return (
        <div className="flex flex-column flex-wrap align-content-center gap-4">
            <div>
                <h1 className="manuka-header">{t("step1.title")}</h1>
                <p className="md:text-lg">{t("step1.description")}</p>
            </div>
            <form className="gap-4 flex flex-column" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Controller
                        name="giftCardCode"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <div className="flex flex-column gap-2">
                                <label htmlFor="giftCardCode" className="text-sm">{t("step1.giftCardInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} placeholder={t("step1.giftCardInputPlaceholder")} id="giftCardCode" className={errors.giftCardCode ? "p-invalid-input" : ""} disabled={redeemSuccesful} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.giftCardCode && (
                        <span className="p-invalid">{errors.giftCardCode.message}</span>
                    )}
                    {apiError?.length > 0 && (
                        <div>
                            {apiError.map((error, index) => (
                                <span key={index} className="p-invalid">
                                    {error.message}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                {!redeemSuccesful ? (
                    <div className="flex justify-content-center mt-5">
                        <Button type="submit" loading={redeemBtnLoading}>{t("buttons.redeemBtn")}</Button>
                    </div>
                ) : null}

            </form>
            {redeemSuccesful ? (
                <div className="flex flex-wrap gap-2 flex-column">
                    {giftCard ? (
                        <div className="blue-box">
                            {giftCard.durationMonths > 0 ? (
                                <span>
                                    {t("step1.congratsBoxDuration", { count: (giftCard.durationMonths / 12) })}
                                </span>
                            ) : (
                                <span>
                                    {t("step1.congratsBoxStorage", { storageSpace: (giftCard.storageMb / 1024)})}
                                </span>
                            )}
                        </div>
                    ) : null}
                    <p className="text-center text-lg">{t("step1.codeRedeemedDescription")}</p>
                    <div className="flex justify-content-center mt-5">
                        <Button onClick={props.moveNext}>{t("buttons.nextBtn")}</Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};