export interface GiftCardState {
  giftCard: GiftCardData | null;
  giftCardCode: string | null;
}

export interface GiftCardData {
  countryCode: string;
  descr: string;
  durationMonths: number;
  name: string;
  priceCents: number;
  storageMb: number;
}

export enum GiftCardActionTypes {
  UPDATE_CODE = "UPDATE_CODE",
  UPDATE_GIFTCARD = "UPDATE_GIFTCARD",
  RESET_GIFTCARD = "RESET_GIFTCARD",
}

export interface UpdateGiftCardCodeAction {
  type: GiftCardActionTypes.UPDATE_CODE;
  payload: string;
}

export interface UpdateGiftCardAction {
  type: GiftCardActionTypes.UPDATE_GIFTCARD;
  payload: GiftCardData;
}

export interface ResetGiftCardAction {
  type: GiftCardActionTypes.RESET_GIFTCARD;
  payload: null;
}

export type GiftCardAction =
  | UpdateGiftCardAction
  | ResetGiftCardAction
  | UpdateGiftCardCodeAction;
