import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: ["querystring", "localStorage", "navigator"],
  caches: ["localStorage"]
};

export const supportedLngs = ["en", "nl"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "nl",
    supportedLngs: supportedLngs,
    detection: DETECTION_OPTIONS,
    resources: {
      en: {
        translation: require("./locales/en.json"),
      },
      nl: {
        translation: require("./locales/nl.json"),
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
