import userReducer from "./UserReducer";
import giftcardReducer from "./GiftcardReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  userData: userReducer,
  giftCard: giftcardReducer,
});

export default rootReducer;
