import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar"
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import AuthService from "../../utils/AuthService";
import { useState } from "react";
import { RegisterFormType } from "../../types/FormTypes";
//import { Link } from "react-router-dom";

export default function RegisterForm({ emitRegister }: any) {
    const { t, i18n } = useTranslation();
    const [regBtnLoading, setRegBtnLoading] = useState<boolean>(false);
    const dateFormat: string = t("common.datepicker_format");

    const getTermsUrl = (): string => {
        return `${process.env.REACT_APP_ABOUTTIME_BASE_URL}/${i18n.resolvedLanguage}/${t("common.termsLink")}`
    }

    const getMinDate = (): Date => {
        const today = new Date();
        const year16YearsAgo = today.getFullYear() - 16;
        return new Date(year16YearsAgo, today.getMonth(), today.getDate());
    }

    const schema = yup.object().shape({
        firstName: yup.string().required(t("errorMessages.required")).max(30, t("errorMessages.maxLength", {len: 30})).trim(),
        lastName: yup.string().required(t("errorMessages.required")).max(30, t("errorMessages.maxLength", {len: 30})).trim(),
        birthDate: yup.date().max(getMinDate(), t("errorMessages.min16years")).required(t("errorMessages.required")),
        emailAddress: yup.string().required(t("errorMessages.required")).trim().max(254, t("errorMessages.maxLength", {len: 254})).email(t("errorMessages.invalidEmail")),
        password: yup.string().required(t("errorMessages.required")).trim().matches(/^(?=.*\d)([^\s]){14,}$/, t("errorMessages.incorrectPassword")),
        confirmPassword: yup.string().oneOf([yup.ref("password")], t("errorMessages.passwordMismatch")),
        termsAccepted: yup.bool().oneOf([true], t("errorMessages.acceptTermsFalse")).required(t("errorMessages.acceptTermsFalse"))
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<RegisterFormType>({
        mode: "onChange",
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: RegisterFormType): Promise<void> => {
        try {
            setRegBtnLoading(true);
            const dob = data.birthDate.toLocaleDateString("en-GB");
            const locale = i18n.language;
            await AuthService.signUp(data.emailAddress, data.password, data.firstName, "", data.lastName, dob, locale);
            emitRegister();
        } catch (err: any) {
            console.log(err);
        } finally {
            setRegBtnLoading(false);
        }
    };

    return (
        <form className="flex flex-column gap-4 md:w-4 w-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-3 justify-content-center flex-wrap">
                <div className="flex-1">
                    <Controller
                        name="firstName"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="firstName" className="text-sm">{t("step2.register.firstNameInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} placeholder={t("step2.register.firstNameInputPlaceholder")} id="firstName" className={errors.firstName ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.firstName && (
                        <span className="p-invalid">{errors.firstName.message}</span>
                    )}
                </div>
                <div className="flex-1">
                    <Controller
                        name="lastName"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="lastName" className="text-sm">{t("step2.register.lastNameInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} placeholder={t("step2.register.lastNameInputPlaceholder")} id="lastName" className={errors.lastName ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.lastName && (
                        <span className="p-invalid">{errors.lastName.message}</span>
                    )}
                </div>
            </div>
            <div className="flex gap-3 justify-content-center flex-wrap">
                <div className="flex-1">
                    <Controller
                        name="birthDate"
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="birthDate" className="text-sm">{t("step2.register.birthDateInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <Calendar {...field} yearNavigator={true} monthNavigator={true} yearRange="1920:2100" maxDate={new Date()} dateFormat={dateFormat} locale={i18n.language} placeholder={t("common.date_format").toLocaleLowerCase()} id="birthDate" className={errors.birthDate ? "p-invalid-input" : ""} />
                            </div>
                        )}
                    />
                    {errors.birthDate && (
                        <span className="p-invalid">{errors.birthDate.message}</span>
                    )}
                </div>
                <div className="flex-1">
                    <Controller
                        name="emailAddress"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="emailAddress" className="text-sm">{t("step2.register.emailAddressInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} type="email" placeholder={t("step2.register.emailAddressInputPlaceholder")} id="emailAddress" className={errors.emailAddress ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.emailAddress && (
                        <span className="p-invalid">{errors.emailAddress.message}</span>
                    )}
                </div>
            </div>
            <div className="flex gap-3 justify-content-center flex-wrap">
                <div className="flex-1">
                    <Controller
                        name="password"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="password" className="text-sm">{t("step2.register.passwordInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} type="password" placeholder={t("step2.register.passwordInputPlaceholder")} id="password" className={errors.password ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.password && (
                        <span className="p-invalid">{errors.password.message}</span>
                    )}
                </div>
                <div className="flex-1">
                    <Controller
                        name="confirmPassword"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <div className="flex flex-column">
                                <label htmlFor="confirmPassword" className="text-sm">{t("step2.register.confPasswordInputLabel")}<span className="p-invalid ml-1">*</span></label>
                                <InputText {...field} type="password" placeholder={t("step2.register.confPasswordInputPlaceholder")} id="confirmPassword" className={errors.confirmPassword ? "p-invalid-input" : ""} value={field.value} onChange={(e) => field.onChange(e.target.value)} />
                            </div>
                        )}
                    />
                    {errors.confirmPassword && (
                        <span className="p-invalid">{errors.confirmPassword.message}</span>
                    )}
                </div>
            </div>
            <div className="flex flex-column">
                <Controller
                    name="termsAccepted"
                    control={control}
                    render={({ field }) => (
                        <div className="flex flex-row align-items-center gap-2 justify-content-center">
                            <Checkbox inputId={field.name} id="acceptTerms" checked={field.value} inputRef={field.ref} className={errors.confirmPassword ? "p-invalid-input" : ""} onChange={(e) => field.onChange(e.checked)} />
                            <label htmlFor="acceptTerms" className="text-sm">
                            <a href={getTermsUrl()} target="_blank" rel="noreferrer">{t("step2.register.acceptTerms")}</a>
                            {/*
                            <Link to="/terms-conditions" target="_blank" className="underline cursor-pointer">{t("step2.register.acceptTerms")}</Link>
                            */}
                            </label>
                        </div>
                    )}
                />
                {errors.termsAccepted && (
                    <span className="p-invalid mt-1 flex justify-content-center">{errors.termsAccepted.message}</span>
                )}
            </div>
            <div className="flex justify-content-center mt-5 mb-6">
                <Button type="submit" loading={regBtnLoading}>{t("buttons.registerBtn")}</Button>
            </div>
        </form>
    );
};