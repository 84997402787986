import { Auth } from "aws-amplify";

class AuthService {
    async login(email, password) {
      return Auth.signIn(email, password);
    }
  
    // Sign up a user with name, email and passwod
    async signUp(email, password, name,middlename,lastname,dob,lang) {
      console.log("Data for sending api",email,password,name,middlename,lastname,dob,lang)
      return Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email, 
            name: name,
            'custom:middlename': middlename,
            family_name:lastname,
            birthdate: dob,
            'custom:lang':lang,
            
          },
          validationData: [], // optional
        })
        .then((data) => {
        })
        .catch((err) => {
          throw(err);
        });
    }

    async resendSignUp(email) {
      return Auth.resendSignUp(email)
      .then((data) => {
      })
      .catch((err) => {
        console.log('There was an error re-sending sign up.');
        throw(err);
      });
    }

    async forgotPasswordApi(email) {
      return Auth.forgotPassword(email)
      .then((data) => {
        console.log("forgot password api returns",data)
      })
      .catch((err) => {
        console.log('There was an error re-sending sign up.');
        throw(err);
      });
    }

    async signOutUser() {
      return Auth.signOut({global: true})
      .then((data) => {
        console.log("User logged out",data)
      })
      .catch((err) => {
        console.log('There was an error sign out api.');
        throw(err);
      });
    }
  
    async deleteUser() {
      return Auth.deleteUser()
      .then((data) => {
        console.log("User deleted",data)
      })
      .catch((err) => {
        console.log('There was an error delete api.');
        throw(err);
      });
    }
  async confirmSignUp(username,code,password) {
    return Auth.forgotPasswordSubmit(username,code,password)
    .then((data) => {
    })
    .catch((err) => {
      console.log('error confirming sign up',err);
      throw(err);
    });
  }
 
  }
  
  const authService = new AuthService();
  
  export default authService;
  