import { useState } from "react";
import Step1 from "./RedeemForm/Step1";
import Step2 from "./RedeemForm/Step2";
import Step3 from "./RedeemForm/Step3";
import Step4 from "./RedeemForm/Step4";

export default function Conf() {
    const [step, setStep] = useState(1);
    const handleNext = () => {
        setStep(step + 1);
    }

    return (
        <div>
            <div className="flex flex-column align-items-center mb-3">
                <div className="progress-bar">
                    <div className="overlap-group">
                        <div className={"divider-h device-0 " + (step >= 2 ? "progress-bar-active" : "progress-bar-inactive")}></div>
                        <div className={"divider-h-2 device-1 " + (step >= 3 ? "progress-bar-active" : "progress-bar-inactive")}></div>
                        <div className={"divider-h-3 device-2 " + (step >= 4 ? "progress-bar-active" : "progress-bar-inactive")}></div>

                        <div className="ellipse progress-bar-active"></div>
                        <div className={"ellipse-2 device-3 " + (step >= 2 ? "progress-bar-active" : "progress-bar-inactive")}></div>
                        <div className={"ellipse-3 device-4 " + (step >= 3 ? "progress-bar-active" : "progress-bar-inactive")}></div>
                        <div className={"ellipse-4 device-5 " + (step >= 4 ? "progress-bar-active" : "progress-bar-inactive")}></div>
                    </div>
                </div>
            </div>
            <div>
                {step === 1 && <Step1 moveNext={handleNext} />}
                {step === 2 && <Step2 moveNext={handleNext} />}
                {step === 3 && <Step3 moveNext={handleNext} />}
                {step === 4 && <Step4 />}
            </div>
        </div>
    );
};