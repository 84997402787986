import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

export default function Header() {
  const { i18n } = useTranslation();
  const getDateFormat = () => {
    return format(new Date(), "dd.MM.yyyy");
  };

  const getCurrentTime = (): string => {
    let today: Date = new Date();
    let time = today.toLocaleTimeString(i18n.language, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hourCycle: "h24",
    });
    return time;
  };

  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    const timeout = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);
    return () => clearInterval(timeout);
  });

  return (
    <header className="flex flex-column">
      <div className="flex flex-wrap align-items-center flex-column mt-5">
        <div className="pb-4">
          <img src="../img/logo.svg" alt="About Time Logo" />
        </div>
        <div className="flex flex-wrap flex-row col-12 justify-content-center align-items-center border-top-1 border-bottom-1 mb-5 p-0">
          <div className="text-sm header-box text-right">
            {getDateFormat()}
          </div>
          <img className="mx-5" src="../img/logo_sm.svg" alt="About Time Logo" />
          <div className="text-sm header-box text-left">
            {currentTime}
          </div>
        </div>
      </div>
    </header>
  );
}
