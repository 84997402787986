import { authHttpClient, apiKeyHttpClient } from "../http-common";
import { RedeemVoucherResponse, GetProductByVoucherResponse } from "../models/ApiResponse";

class VoucherService {
    async getVouchers(query: string):Promise<GetProductByVoucherResponse> {
        const response = await apiKeyHttpClient.post("", query);
        return response.data.data.getProductByVoucher;
    }
    async redeemGiftcard(query: string, token: string):Promise<RedeemVoucherResponse> {
        const response = await authHttpClient.post("", query, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data.data.redeemVoucher;
    }
}

const voucherService = new VoucherService();
export default voucherService;