import {Button} from "primereact/button";
import { useTranslation } from "react-i18next";
import VoucherService from "../../services/VoucherService";
import { redeemVoucher } from "../../utils/GraphqlQueries";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/rootState";
import { format } from "date-fns";
import { updateMemberShip } from "../../redux/actions/UserdataActions";

export default function Step3({moveNext}: any) {
    const { t } = useTranslation();
    const giftCard = useSelector((state: RootState) => state.giftCard);
    const user = useSelector((state: RootState) => state.userData.userData);
    const membership = useSelector((state: RootState) => state.userData.memberShipData);
    const dispatch = useDispatch();

    const getPrepaidDate = () => {
        let d = new Date(membership!.validTo);
        return format(d, t("common.date_format"));
    };

    const getDataStorage = () => {
        if(membership && membership.totalStorageMb > 2048)
            return `${(membership.totalStorageMb/1024).toFixed(2)}GB`;
        else
            return t("step3.baseDataStorage");
    }

    const callApi = async (): Promise<void> => {
        const graphql: string = JSON.stringify({
            query: redeemVoucher(giftCard.giftCardCode!),
            variables: {}
        });

        const response = await VoucherService.redeemGiftcard(graphql, user!.UserToken);
        dispatch(updateMemberShip(response.membership));
        moveNext();
    }

    return (
        <div className="flex flex-column flex-wrap text-center gap-4">
            <div>
                <h1 className="manuka-header">{t("step3.title")}</h1>
                <p className="md:text-lg">{t("step3.loggedInAs")} {user!.UserName}, {user!.Email}</p>

                {giftCard && giftCard.giftCard ? (
                    <div>
                        {giftCard.giftCard.durationMonths > 0 ? (
                            <div>
                                <p className="md:text-lg">{t("step3.prepaidExpiration")} {getPrepaidDate()}</p>
                                <p className="md:text-lg">{t("step3.confirmDurationGiftcard", {count: (giftCard.giftCard.durationMonths / 12)})}</p>
                            </div>
                        ) : (
                            <div>
                                <p className="md:text-lg">{t("step3.dataStorage")} { getDataStorage() }</p>
                                <p className="md:text-lg">{t("step3.confirmStorageGiftcard", {storageSpace: (giftCard.giftCard.storageMb / 1024)})}</p>    
                            </div>
                        )}
                        <div className="flex justify-content-center mt-6">
                            <Button onClick={callApi}>{t("buttons.confirmBtn")}</Button>
                        </div>
                    </div>
                ) : (
                    <p className="p-invalid">{t("errorMessages.unknownError")}</p>
                )}
            </div>
        </div>
    );
};