//import { Image } from "primereact/image"
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

export default function Footer() {
  const { t } = useTranslation();

  const currentYear = (): number => {
    return new Date().getFullYear();
  }

  return (
    <footer className="flex flex-wrap flex-column md:flex-row md:justify-content-between sm:justify-content-center px-4 pt-3 align-items-center border-top-1">
      <div className="text-sm flex-order-1 md:flex-order-0">
        &copy; {currentYear()} {t("footer.copyright")}
      </div>
      <div className="flex flex-wrap gap-4 flex-order-0 md:flex-order-1 mb-8 md:mb-0">
        <LanguageSwitcher />
        {/* 
        <Image src="img/icon-tiktok.svg" height="21" alt="Tiktok Logo"></Image>  
        <Image src="img/icon-whatsapp.svg" height="21" alt="Whatsapp Logo"></Image>
        <Image src="img/icon-insta.svg" height="21" alt="Instagram Logo"></Image>
        <Image src="img/icon-facebook.svg" height="21" alt="Facebook Logo"></Image>
        */}
      </div>
    </footer>
  );
};