import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootState";
import { format } from "date-fns";

export default function ConfirmedBundle() {
    const membership = useSelector((state: RootState) => state.userData.memberShipData);
    const giftCard = useSelector((state: RootState) => state.giftCard.giftCard);
    const { t } = useTranslation();
    
    const getPrepaidDate = () => {
        if (membership) {
            let d = new Date(membership.validTo);
            return format(d, t("common.date_format"));
        }
    };

    const formatStorage = (storage: number | undefined) => {
        if (!storage)
            return 0;
        return `${(storage/1024).toFixed(2)} GB`;
    }
    
    return (
        <div className="flex flex-column flex-wrap text-center gap-4">
            <div>
                <h1 className="manuka-header">{t("step4.title")}</h1>
                {giftCard && membership ? (
                    <div>
                        {giftCard.durationMonths > 0 ? (
                            <div>
                                <p className="md:text-lg">
                                    {t("step4.cardAdded", {count: (giftCard.durationMonths / 12)})}
                                </p>
                                <p className="md:text-lg">
                                    {t("step4.prepaidEndsOn")} {getPrepaidDate()}
                                </p>                
                            </div>
                        ) : (
                            <div>
                                <p className="md:text-lg">
                                    {t("step4.storageAdded", {cardStorage: formatStorage(giftCard.storageMb), totalStorage: formatStorage(membership.totalStorageMb)})}
                                </p>
                            </div>
                        )}
                        <p className="md:text-lg">
                            {t("step4.closeWindow")}
                        </p>
                    </div>
                ) : (
                    <p className="p-invalid">{t("errorMessages.unknownError")}</p>
                )}
            </div>
        </div>
    );
};