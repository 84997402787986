export function getProductByVoucher(lang: string, code: string) {
  return `
        query MyQuery {
            getProductByVoucher(lang: "${lang}", code: "${code}") {
                product {
                    countryCode
                    descr
                    durationMonths
                    name
                    priceCents
                    storageMb
                }
                isSuccess
                errors {
                    code
                    message
                }
            }
        }`;
}

export function getUserProfileByUserId(userid: string) {
  return `
        query UserQuery {
            getUserProfileByUserId(userId:"${userid}" ) {
            address {
                addressLine1
                addressLine2
                city
                country
                postalCode
                state
            }
            countryOfBirth
            createdAt
            dateOfBirth
            email
            firstName
            gender
            identityNo
            lang
            lastName
            maritalStatus
            maidenName
            mobileNo
            phoneNo
            placeOfBirth
            prefix
            profilePic
            salutation
            status
            statusAcknowledged
            title
            updatedAt
            updatedBy
            useFormalForm
            userId
                updatedByUserProfile {
                    userId
                    firstName
                    lastName
                    email
                    userId
                }
                membership {
                validTo
                validFrom
                usedStorageMb
                totalStorageMb
                }
            }
        }`;
}

export function redeemVoucher(code: string) {
  return `mutation MyMutation {
        redeemVoucher(code: "${code}") {
            membership {
                totalStorageMb
                usedStorageMb
                userId
                validFrom
                validTo
            }
            isSuccess
            errors {
                code
                message
            }
        }
    }`;
}
