import { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ConfirmReg from "./ConfirmReg";
import ForgotPassword from "./ForgotPassword";
import {useTranslation} from "react-i18next"

export default function Step2(prop: any) {
    const [showLogin, switchLogin] = useState(true);
    const [userRegistered, switchRegister] = useState(false);
    const [showForgotPsw, switchForgotPsw] = useState(false);
    const { t } = useTranslation();

    const handleRegister = () => {
        switchRegister(true);
    }

    return (
        <div>
            {showForgotPsw ? (
                <ForgotPassword emitFormSubmit={() => switchForgotPsw(false)} />
            ) : (
                <div className="flex flex-column flex-wrap align-content-center gap-4 col-12">
                    {!userRegistered && (
                        <div className="flex flex-column flex-wrap align-items-center">
                            <h1 className="manuka-header">{showLogin ? "Login" : "Register"}</h1>
                            <div className="flex flex-row gap-5">
                                <p
                                    className={
                                        "domaine-font cursor-pointer " +
                                        (showLogin ? "border-bottom-1" : "")
                                    }
                                    onClick={() => switchLogin(true)}
                                >
                                    {t("step2.login.title")}
                                </p>
                                <p
                                    className={
                                        "domaine-font cursor-pointer " +
                                        (!showLogin ? "border-bottom-1" : "")
                                    }
                                    onClick={() => switchLogin(false)}
                                >
                                    {t("step2.register.title")}
                                </p>
                            </div>
                        </div>
                    )}

                    {showLogin ? (
                        <LoginForm forgotPswClicked={() => switchForgotPsw(true)} moveNext={prop.moveNext} />
                    ) : !userRegistered ? (
                        <RegisterForm emitRegister={handleRegister} />
                    ) : (
                        <ConfirmReg handleRegConfirm={() => { switchLogin(true); switchRegister(false) }} />
                    )}
                </div>
            )}
        </div>
    );
};