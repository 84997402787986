import {
  GiftCardActionTypes,
  GiftCardData,
  ResetGiftCardAction,
  UpdateGiftCardAction,
  UpdateGiftCardCodeAction,
} from "../../types/GiftcardTypes";

export const updateGiftCard = (
  giftCardData: GiftCardData
): UpdateGiftCardAction => ({
  type: GiftCardActionTypes.UPDATE_GIFTCARD,
  payload: giftCardData,
});

export const resetGiftCard = (): ResetGiftCardAction => ({
  type: GiftCardActionTypes.RESET_GIFTCARD,
  payload: null,
});

export const updateGiftCardCode = (code: string): UpdateGiftCardCodeAction => ({
  type: GiftCardActionTypes.UPDATE_CODE,
  payload: code,
});
