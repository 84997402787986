import {
  UserDataState,
  UserDataActionTypes,
  UserDataAction,
} from "../../types/UserTypes";

const initialState: UserDataState = {
  userData: null,
  memberShipData: null,
};

const giftcardReducer = (
  state = initialState,
  action: UserDataAction
): UserDataState => {
  switch (action.type) {
    case UserDataActionTypes.UPDATE_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case UserDataActionTypes.RESET_USERDATA:
      return {
        ...state,
        userData: null,
      };
    case UserDataActionTypes.UPDATE_MEMBERSHIP:
      return {
        ...state,
        memberShipData: action.payload,
      };
    default:
      return state;
  }
};

export default giftcardReducer;
