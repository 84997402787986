import { Membership } from "../../models/Membership";
import {
  UserDataActionTypes,
  UserData,
  UpdateUserDataAction,
  UpdateMembershipAction,
} from "../../types/UserTypes";

export const updateUserData = (userData: UserData): UpdateUserDataAction => ({
  type: UserDataActionTypes.UPDATE_USERDATA,
  payload: userData,
});

export const updateMemberShip = (
  memberShip: Membership
): UpdateMembershipAction => ({
  type: UserDataActionTypes.UPDATE_MEMBERSHIP,
  payload: memberShip,
});
