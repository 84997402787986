import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
export default function ConfirmReg(prop: any) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-column flex-wrap text-center gap-2">
      <div>
        <h1 className="manuka-header">{t("step2.register.confirm.title")}</h1>
        <p className="md:text-lg">
          {t("step2.register.confirm.confirmDescription")}
        </p>
        <p className="md:text-lg">
          {t("step2.register.confirm.loginAfterConfirm")}
        </p>
      </div>
      <div className="flex justify-content-center mt-5">
        <Button onClick={prop.handleRegConfirm}>{t("buttons.loginBtn")}</Button>
      </div>
    </div>
  );
}
