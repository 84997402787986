import {
  GiftCardState,
  GiftCardActionTypes,
  GiftCardAction,
} from "../../types/GiftcardTypes";

const initialState: GiftCardState = {
  giftCard: null,
  giftCardCode: null,
};

const giftcardReducer = (
  state = initialState,
  action: GiftCardAction
): GiftCardState => {
  switch (action.type) {
    case GiftCardActionTypes.UPDATE_GIFTCARD:
      return {
        ...state,
        giftCard: action.payload,
      };
    case GiftCardActionTypes.RESET_GIFTCARD:
      return {
        ...state,
        giftCard: null,
      };
    case GiftCardActionTypes.UPDATE_CODE:
      return {
        ...state,
        giftCardCode: action.payload,
      };
    default:
      return state;
  }
};

export default giftcardReducer;
